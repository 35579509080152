<template>
  <div class="TryFrameworkContent">
    <h3>框架试戴</h3>
    <el-button type="primary" @click="add">增加试戴</el-button>
    <div v-for="(item, idx) in postDatas">
      <div class="top">
        <div class="titles">试戴{{idx+1}}</div>
        <div class="cz">
          <el-button @click="dele(idx)" type="danger">删除</el-button>
          <el-button type="primary">提交</el-button>
        </div>
      </div>
      <div class="srA">
        <div>
          <ul class="content-sr mar-20">
            <li></li>
            <li>球镜</li>
            <li>柱镜</li>
            <li>轴向</li>
            <li>远用矫正视力</li>
            <li>ADD</li>
            <li>近用矫正视力</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input :class="[srval=='S_OP_R_T_DSOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_DSOD, -30, 20)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_DSOD, 'S_OP_R_T_DSOD', idx, 1)" @change="zh(item, 'S_OP_R_T_DSOD', 1)" v-model="item.S_OP_Json.S_OP_R_T_DSOD" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_DCOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_DCOD, -10, 9)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_DCOD, 'S_OP_R_T_DCOD', idx, 1)" @change="zh(item, 'S_OP_R_T_DCOD', 1)" v-model="item.S_OP_Json.S_OP_R_T_DCOD" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_XOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_XOD, 0, 180)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_XOD, 'S_OP_R_T_XOD', idx)" @change="zh(item, 'S_OP_R_T_XOD')" v-model="item.S_OP_Json.S_OP_R_T_XOD" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_DVAOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_DVAOD, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_DVAOD, 'S_OP_R_T_DVAOD', idx)" @change="zh(item, 'S_OP_R_T_DVAOD')" v-model="item.S_OP_Json.S_OP_R_T_DVAOD" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_AddOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_AddOD, 0, 20)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_AddOD, 'S_OP_R_T_AddOD', idx)" @change="zh(item, 'S_OP_R_T_AddOD')" v-model="item.S_OP_Json.S_OP_R_T_AddOD" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_NVAOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_NVAOD, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_NVAOD, 'S_OP_R_T_NVAOD', idx)" @change="zh(item, 'S_OP_R_T_NVAOD')" v-model="item.S_OP_Json.S_OP_R_T_NVAOD" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr mar-20">
            <li>OS</li>
            <li><el-input :class="[srval=='S_OP_R_T_DSOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_DSOS, -30, 20)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_DSOS, 'S_OP_R_T_DSOS', idx, 1)" @change="zh(item, 'S_OP_R_T_DSOS', 1)" v-model="item.S_OP_Json.S_OP_R_T_DSOS" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_DCOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_DCOS, -10, 9)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_DCOS, 'S_OP_R_T_DCOS', idx, 1)" @change="zh(item, 'S_OP_R_T_DCOS', 1)" v-model="item.S_OP_Json.S_OP_R_T_DCOS" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_XOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_XOS, 0, 180)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_XOS, 'S_OP_R_T_XOS', idx)" @change="zh(item, 'S_OP_R_T_XOS')" v-model="item.S_OP_Json.S_OP_R_T_XOS" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_DVAOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_DVAOS, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_DVAOS, 'S_OP_R_T_DVAOS', idx)" @change="zh(item, 'S_OP_R_T_DVAOS')" v-model="item.S_OP_Json.S_OP_R_T_DVAOS" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_AddOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_AddOS, 0, 20)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_AddOS, 'S_OP_R_T_AddOS', idx)" @change="zh(item, 'S_OP_R_T_AddOS')" v-model="item.S_OP_Json.S_OP_R_T_AddOS" placeholder="请输入内容"></el-input></li>
            <li><el-input :class="[srval=='S_OP_R_T_NVAOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_NVAOS, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_NVAOS, 'S_OP_R_T_NVAOS', idx)" @change="zh(item, 'S_OP_R_T_NVAOS')" v-model="item.S_OP_Json.S_OP_R_T_NVAOS" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
        <el-button class="last" @click="tb(idx)" icon="el-icon-refresh" type="primary">同步数据</el-button>
      </div>
      <div class="meed mar-20">
        <ul>
          <li></li>
          <li>远用瞳距</li>
          <li>近用距离</li>
          <li>近用瞳距</li>
          <li style="padding-left: 0px;">棱镜</li>
          <li>基底</li>
        </ul>
        <div class="contentu">
          <div class="left">
            <div class="lefta">
              <p>OD</p>
              <div  @change="zh(item, 'S_OP_R_T_FarPDOD')" class="box"><el-input :class="[srval=='S_OP_R_T_FarPDOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_FarPDOD, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_FarPDOD, 'S_OP_R_T_FarPDOD', idx)" v-model="item.S_OP_Json.S_OP_R_T_FarPDOD" placeholder="请输入内容"></el-input>mm</div>
            </div>
            <div class="lefta mar-20">
              <p>OS</p>
              <div @change="zh(item, 'S_OP_R_T_FarPDOS')" class="box"><el-input :class="[srval=='S_OP_R_T_FarPDOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_FarPDOS, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_FarPDOS, 'S_OP_R_T_FarPDOS', idx)" v-model="item.S_OP_Json.S_OP_R_T_FarPDOS" placeholder="请输入内容"></el-input>mm</div>
            </div>
          </div>
          <div class="box" style="padding-right: 0.4rem;"><el-input :class="[srval=='S_OP_R_T_NearDistance'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_NearDistance, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_NearDistance, 'S_OP_R_T_NearDistance', idx)" @change="zh(item, 'S_OP_R_T_NearDistance')" v-model="item.S_OP_Json.S_OP_R_T_NearDistance" placeholder="请输入内容"></el-input>cm</div>
          <div class="rights">
            <div class="padd-40">
              <div class="box"><el-input :class="[srval=='S_OP_R_T_NearPDOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_NearPDOD, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_NearPDOD, 'S_OP_R_T_NearPDOD', idx)" @change="zh(item, 'S_OP_R_T_NearPDOD')" v-model="item.S_OP_Json.S_OP_R_T_NearPDOD" placeholder="请输入内容"></el-input>mm</div>
              <div class="box mar-20"><el-input :class="[srval=='S_OP_R_T_NearPDOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_NearPDOS, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_NearPDOS, 'S_OP_R_T_NearPDOS', idx)" @change="zh(item, 'S_OP_R_T_NearPDOS')" v-model="item.S_OP_Json.S_OP_R_T_NearPDOS" placeholder="请输入内容"></el-input>mm</div>
            </div>
            <div class="padd-40">
              <div class="box"><el-input :class="[srval=='S_OP_R_T_PrismOD'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_PrismOD, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_PrismOD, 'S_OP_R_T_PrismOD', idx)" @change="zh(item, 'S_OP_R_T_PrismOD')" v-model="item.S_OP_Json.S_OP_R_T_PrismOD" placeholder="请输入内容"></el-input>△</div>
              <div class="box mar-20"><el-input :class="[srval=='S_OP_R_T_PrismOS'&&itemidx==idx?'xzs':'', srjc(item.S_OP_Json.S_OP_R_T_PrismOS, 0.01, 2)?'cw':'']" @focus="getgb(item.S_OP_Json.S_OP_R_T_PrismOS, 'S_OP_R_T_PrismOS', idx)" @change="zh(item, 'S_OP_R_T_PrismOS')" v-model="item.S_OP_Json.S_OP_R_T_PrismOS" placeholder="请输入内容"></el-input>△</div>
            </div>
            <div class="padd-40">
              <div class="box">
                <el-select
                    class="srr"
                    v-model="item.S_OP_Json.S_OP_R_T_BaseOD"
                    allow-create
                    collapse-tags
                    filterable
                    default-first-option
                    placeholder="请选择或输入">
                  <el-option
                      v-for="item in jd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="box mar-20">
                <el-select
                    class="srr"
                    v-model="item.S_OP_Json.S_OP_R_T_BaseOS"
                    allow-create
                    collapse-tags
                    filterable
                    default-first-option
                    placeholder="请选择或输入">
                  <el-option
                      v-for="item in jd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p>备注</p>
        <el-input
            class="bzs"
            type="textarea"
            placeholder="请输入内容"
            v-model="item.S_OP_Json.S_OP_R_T_Remark"
            :autosize="{ minRows: 2, maxRows: 2 }"
            resize="none"
            show-word-limit
        >
        </el-input>
      </div>
    </div>
    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
import keys from "@/components/keyboard/keyHome";
export default {
  name: "TryFrameworkContent",
  data() {
    return {
      datas: '',
      postDatas: [],
      oldPostData: [],
      jd: [
        {
          value: '内',
          label: '内'
        },
        {
          value: '外',
          label: '外'
        },
        {
          value: '上',
          label: '上'
        },
        {
          value: '下',
          label: '下'
        }
      ],
      jsond: {
        S_OP_R_T_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_R_T_DVAOD: '',
        S_OP_R_T_BaseOS: '',
        S_OP_R_T_DCOS: '',
        S_OP_R_T_DSOD: '',
        S_OP_R_T_AddOS: '',
        S_OP_R_T_NearDistance: '',
        S_OP_R_T_XOS: '',
        S_OP_R_T_NearPDOS: '',
        S_OP_R_part3_isDone: '',
        S_OP_R_T_NVAOD: '',
        S_OP_R_T_XOD: '',
        S_OP_R_T_BaseOD: '',
        S_OP_R_T_DVAOS: '',
        S_OP_ID: '',
        S_OP_R_T_PrismOS: '',
        S_OP_R_T_DSOS: '',
        S_OP_R_T_PrismOD: '',
        S_OP_R_T_Remark: '',
        S_OP_R_T_AddOD: '',
        S_OP_R_T_DCOD: '',
        S_OP_R_T_Type: '',
        S_OP_R_T_FarPDOD: '',
        S_OP_R_T_FarPDOS: '',
        S_OP_R_T_NearPDOD: '',
        S_OP_R_T_Number: '',
        S_OP_R_T_NVAOS: '',
        S_OP_R_T_Confirm: ''
      },
      addData: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.user.UserId,
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "",
        S_OP_Json:  pf.conversionJson(this.jsond),
        S_OP_Model: "S_OP_Try180726",
        S_OP_Time: "",
        S_OP_Type: "Refractive",
        S_OP_XML: ""
      },

      issr: false,
      srval: '',
      showkeys: false,
      keyval: '',
      itemidx: '',
      iszf: false,

    }
  },
  watch: {
    postDatas: {
      handler(n, o) {
        this.$store.commit('eyeTest/upTryData', n)
      },
      deep: true
    }
  },
  created() {
    // this.postDatas = this.$store.state.eyeTest.tryData
    document.addEventListener('click', this.getXY, false)
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getTryD()
    .then(res => {
      // console.log(res.GetListResult)
      // return
      if (res.GetListResult && res.GetListResult.length > 0) {
        this.postDatas = res.GetListResult.map(item => {
          item.S_OP_Json = this._clJson(item.S_OP_Json)
          return item
        })
      }
    })
  },
  methods: {
    add() {
      let obj = JSON.parse(JSON.stringify(this.addData))
      obj.S_OP_Json = JSON.parse(JSON.stringify(this.jsond))
      this.postDatas.push(obj)
      obj = null
    },

    tb(idx) {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_DSOD', datas.S_OP_R_SS_DSOD)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_DCOD', datas.S_OP_R_SS_DCOD)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_XOD', datas.S_OP_R_SS_XOD)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_DVAOD', datas.S_OP_R_SS_DVAOD)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_AddOD', datas.S_OP_R_SS_AddOD)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_NVAOD', datas.S_OP_R_SS_NVAOD)


      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_DSOS', datas.S_OP_R_SS_DSOS)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_DCOS', datas.S_OP_R_SS_DCOS)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_XOS', datas.S_OP_R_SS_XOS)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_DVAOS', datas.S_OP_R_SS_DVAOS)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_AddOS', datas.S_OP_R_SS_AddOS)
      this.$set(this.postDatas[idx].S_OP_Json, 'S_OP_R_T_NVAOS', datas.S_OP_R_SS_NVAOS)
    },

    zh(item, key, isadd) {
      if (isadd) {
        if (Number(item[key]) > 0 && item[key].toString().indexOf('+')==-1) {
          item[key] = "+" + item[key]
        }
      } else {
        item[key] = item[key] !==''?item[key].toString():''
      }
    },

    dele(idx) {
      this.postDatas.splice(idx, 1)
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, idx, isj=0) {
      // console.log(e)
      // document.activeElement.blur();
      this.keyval = str
      this.srval = key
      this.itemidx = idx
      this.issr = true
      this.iszf = isj
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }
    },

    changeval(e) {
      this.keyval = e
      this.postDatas[this.itemidx].S_OP_Json[this.srval] = e
      if (this.postDatas[this.itemidx].S_OP_Json[this.srval] && !isNaN(Number(this.postDatas[this.itemidx].S_OP_Json[this.srval])) && this.iszf) {
        if (this.postDatas[this.itemidx].S_OP_Json[this.srval] && Number(this.postDatas[this.itemidx].S_OP_Json[this.srval]) > 0 && this.postDatas[this.itemidx].S_OP_Json[this.srval].indexOf('+') < 0  && this.postDatas[this.itemidx].S_OP_Json[this.srval].indexOf('-') < 0) {
          this.$set(this.postDatas[this.itemidx].S_OP_Json, this.srval, '+'+e)
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },
  components: {
    KEYS: keys
  }
}
</script>

<style scoped lang="scss">
  .TryFrameworkContent {
    width: 90%;
    margin: 0 auto;
  }
  .mar-20{margin-top: 20px}
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    background: #efefef;
    padding: 10px 0;
    border-radius: 5px;
    .titles {
      padding-left: 40px;
    }
    .cz {padding-right: 40px}
  }
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 4vw;
        justify-content: start;
        margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-top: 32px;
    }
  }
  ::v-deep .srA .content-sr input:hover {background: #e8e6e6;}
  ::v-deep .xzs input {background: #e8e6e6;}
  h3 {padding-bottom: 0.4rem}
  .meed {
    ul{
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 4vw;
        justify-content: start;
        margin-right: 0.05rem;
      }
    }
    .contentu {
      display: flex;
      justify-content: start;
      align-items: center;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
      .box {
        width: 8vw;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .left {
        margin-right: 0.4rem;
        .lefta {
          display: flex;
          align-items: center;
          p {width: 4vw;text-align: left;}
        }
      }
      .rights {
        display: flex;
        align-items: center;
        justify-content: start;
        .padd-40 {padding-right: 0.4rem;}
      }
    }
  }
  ::v-deep .meed input:hover {background: #e8e6e6;}
  .bottom {
    margin: 0 auto;
    display: flex;
    justify-content: start;
    margin-top: 40px;
    .bzs {
      width: 95%;
    }
    p {width: 5%;text-align: left}
  }
  ::v-deep .cw input{background: #ef4674;}
</style>
